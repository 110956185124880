// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./en.json";
import esTranslations from "./ro.json";

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: {
        translation: enTranslations
      },
      ro: {
        translation: esTranslations
      }
    },
    lng: "ro", 
    fallbackLng: "ro", 
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
