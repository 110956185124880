import {SeatLightDto} from "../../core/types";
import {SeatCategories} from "../../core/enums";
import {motion} from "framer-motion";
import {Box, Flex, Grid, IconButton, Text, VStack} from "@chakra-ui/react";
import {getCategoryColor} from "../../core/helpers";
import {DeleteIcon} from "@chakra-ui/icons";

interface SeatProps {
    seat: SeatLightDto;
    deselectSeat: (seatId: string) => void;
    animate: boolean;
    getPriceName: (c: SeatCategories) => {name: string, price: number};
}

const MotionFlex = motion(Flex);

const SelectedSeatRow = ({ seat, deselectSeat, animate, getPriceName }: SeatProps) => {
    const handleClick = () => {
        deselectSeat(seat.id);
    };

    const transition = {
        duration: 1,
        type: "spring",
        damping: 10,
        stiffness: 100,
    };

    return (
        <MotionFlex
            direction="row"
            justifyContent="left"
            borderBottom="1px"
            borderColor="gray.200"
            py={2}
            initial={{ opacity: 0, x: -200 }}
            animate={animate ? { opacity: 1, x: 0 } : { opacity: 0, x: -200 }}
            transition={transition}>
             <Grid templateColumns="80% 20%" w={'100%'}>
                <VStack w="100%" align={'start'} gap={0}>
                    <Flex alignItems={'center'} >
                        <Box
                            width="15px"
                            height="15px"
                            borderRadius="50%"
                            backgroundColor={getCategoryColor(seat.selectedCategory)}
                            marginRight={2}
                        />
                        <Text fontFamily={'Roboto Condensed'} fontSize={'md'}>{`${seat.row} ${seat.number} ${seat.section}`}</Text>
                    </Flex>
                    <Flex>
                        <Text fontFamily={'Roboto Condensed'} fontSize={'md'} mt={'-0.5em'}>{getPriceName(seat.selectedCategory).price} LEI ({getPriceName(seat.selectedCategory).name})</Text>
                    </Flex>
                </VStack>
                <Box w="100%" h="10" >                
                    <IconButton
                    aria-label="Delete seat"
                    variant={'outline'}
                    color={'black'}
                    icon={<DeleteIcon />}
                    onClick={handleClick}/>
                </Box>
            </Grid>
    
        </MotionFlex>
    );
}

export default SelectedSeatRow;