import {CategoryNamePrice} from "../../core/types";
import {Box, Flex, Text} from "@chakra-ui/react";
import {getCategoryColor} from "../../core/helpers";

interface CategoryProps {
    category: CategoryNamePrice;
    isChanging: boolean;
}

const CategoryBox = ({ category, isChanging }: CategoryProps) => {
    return (
        <Flex alignItems="center">
            <Box
                width="15px"
                height="15px"
                borderRadius="50%"
                backgroundColor={getCategoryColor(category.category)}
                marginRight={2}
            />
            <Text fontFamily={'Roboto Condensed'} fontSize={'md'}>{isChanging ? category.changeName: category.name} - {category.price} LEI</Text>
        </Flex>
    );
};

export default CategoryBox;