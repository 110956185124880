
import {
    Box, Checkbox,Text,
    Flex, FormControl, FormErrorMessage, FormLabel, Input, Stack, useBreakpointValue, Grid, Spinner,
} from "@chakra-ui/react";
import TheaterHeader from "../TheaterHeader";
import RepDetails from "../RepDetails";
import {useContext, useRef} from "react";
import {MainContext} from "../../contexts/MainContext";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import useOrdersRepo from "../../hooks/api/useOrdersRepo";
import {CreateOrder, CreateOrderResponse} from "../../core/types";
import { useEffect } from "react";
import {useNavigate} from "react-router-dom";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FaTicketAlt } from "react-icons/fa";
import { SessionStatuses } from "../../core/enums";
import React from "react";



function FillData() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { rep, theater, getPriceName} = useContext(MainContext);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const { createOrder, cancelOrder } = useOrdersRepo();
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [agreeTermsError, setAgreeTermsError] = useState("");
    const { t, i18n } = useTranslation();

    const runCancelReserve = async () => {
        const response = await cancelOrder();
        setIsLoading(false);
        if (response === null || response.status === SessionStatuses.Payed)
            navigate("/");
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!sessionStorage["sessionId"])
            navigate("/");
        else {
            if (!rep.id) {
                setIsLoading(true);
                runCancelReserve().catch(null);
            }
            const storedData = localStorage.getItem("formData");
            if (storedData) {
                const formData = JSON.parse(storedData);
                setFirstName(formData.firstName);
                setLastName(formData.lastName);
                setEmail(formData.email);
                setPhone(formData.phone);
                setNewsletter(true);
            }
        }
        setDisableSubmit(false);
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };

    const handleAgreeTermsChange = () => {
        setAgreeTerms(!agreeTerms);
    };

    const handleNewsletterChange = () => {
        setNewsletter(!newsletter);
    };

    const handleSubmit = async () => {

        setDisableSubmit(true);
        let isValid = true;

        // Reset error states before validation
        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setPhoneError("");
        setAgreeTermsError("");

        // Validation
        if (!firstName) {
            setFirstNameError(t("INFO_ERROR_REQUIRED"));
            isValid = false;
        } else if (firstName.length < 3) {
            setFirstNameError(t("INFO_ERROR_FN"));
            isValid = false;
        }

        if (!lastName) {
            setLastNameError(t("INFO_ERROR_REQUIRED"));
            isValid = false;
        } else if (lastName.length < 3) {
            setLastNameError(t("INFO_ERROR_LN"));
            isValid = false;
        }

        if (!email) {
            setEmailError(t("INFO_ERROR_REQUIRED"));
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError(t("INFO_ERROR_EMAIL"));
            isValid = false;
        }

        if (!phone) {
            setPhoneError(t("INFO_ERROR_REQUIRED"));
            isValid = false;
        } else if (!/^(0\d{9}|\+\d{12})$/.test(phone)) {
            setPhoneError(t("INFO_ERROR_PHONE"));
            isValid = false;
        }

        if (!agreeTerms) {
            setAgreeTermsError(t("INFO_ERROR_AGREE"));
            isValid = false;
        }

        if (!isValid) {
            setDisableSubmit(false);
            return;
        }


        const formData = {
            firstName,
            lastName,
            email,
            phone,
            newsletter,
        };

        localStorage.setItem("formData", JSON.stringify(formData));

        recaptchaRef.current?.execute();

        try {
            let timeoutId: NodeJS.Timeout;
            const captchaValue = await new Promise<string>((resolve, reject) => {
                
                const intervalId = setInterval(() => {
                    const value = recaptchaRef.current?.getValue();
                    if (value) {
                        clearInterval(intervalId);
                        clearTimeout(timeoutId);  
                        resolve(value);
                    }
                }, 100);

                
                timeoutId = setTimeout(() => {
                    clearInterval(intervalId);
                    reject(new Error('reCAPTCHA timeout'));
                }, 5000);
            });

            const order: CreateOrder = {
                firstName,
                lastName,
                email,
                phone,
                receiveNewsletter: newsletter,
                captchaValue,
                seats: rep.seats.filter(seat => seat.isSelected).map(seat => {
                    return {id: seat.id, category: seat.selectedCategory}
                })
            };
            const response: CreateOrderResponse = await createOrder(order);

            if(response.isError) {
                toast.error(response.errorMessage);
            } else {
                if (response.postParams && response.postParams.length > 0) {
                    postFormData(response);
                } else {
                    window.location.href = response.paymentUrl;
                }
            }
        } catch (error) {
            setDisableSubmit(false);
            console.error(error);
        }
    };

    const  postFormData = (response: CreateOrderResponse) => {
        // Create a form
        const form = document.createElement('form');
        form.method = 'post';
        form.action = response.paymentUrl;
    
        // Add parameters as hidden form fields
        response.postParams.forEach(param => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = param.name;
            input.value = param.value;
            form.appendChild(input);
        });
    
        // Append the form to the document
        document.body.appendChild(form);
    
        // Submit the form
        form.submit();
    }

    const layout = useBreakpointValue({ base: "mobile", md: "desktop" });
    
    return (
        <Flex flexDirection="column" maxWidth="1240px" mx="auto" p={layout === "mobile" ? 0 : 4}>
            <TheaterHeader theater={theater} />
            {isLoading ? (
              <Flex justifyContent="center" alignItems="center">
                  <Spinner size="xl" thickness="4px" color="blue.500" />
              </Flex>
            ) : (
            <React.Fragment>
                <Box height="200px" boxShadow={'lg'} background={'white'}>
                    <RepDetails rep={rep} currentPoint={2} isMobile={layout === "mobile"}/>
                </Box>
                <Box height="700px" boxShadow={'lg'}  background={'white'} px={layout === "mobile" ? 4: 0} fontFamily={'Roboto Condensed'}>
                    <Text textAlign={"center"} mt={4}>{t("INFO_WARNING")}</Text>
                    <Flex justifyContent="center" alignItems="top" height="100%" mt={4}>
                        <Stack spacing={2} >
                            <FormControl id="firstName" isInvalid={!!firstNameError} w={300}>
                                <FormLabel color={'#777'}>{t("INFO_FN")}</FormLabel>
                                <Input type="text" value={firstName} onChange={handleFirstNameChange}/>
                                <FormErrorMessage>{firstNameError}</FormErrorMessage>
                            </FormControl>
                            <FormControl id="lastName" isInvalid={!!lastNameError}  w={300}>
                                <FormLabel color={'#777'}>{t("INFO_LN")}</FormLabel>
                                <Input type="text" value={lastName} onChange={handleLastNameChange} />
                                <FormErrorMessage>{lastNameError}</FormErrorMessage>
                            </FormControl>
                            <FormControl id="email" isInvalid={!!emailError}  w={300}>
                                <FormLabel color={'#777'}>{t("INFO_EMAIL")}</FormLabel>
                                <Input type="email" value={email} onChange={handleEmailChange} />
                                <FormErrorMessage>{emailError}</FormErrorMessage>
                            </FormControl>
                            <FormControl id="phone" isInvalid={!!phoneError}  w={300}>
                                <FormLabel color={'#777'}>{t("INFO_PHONE")}</FormLabel>
                                <Input type="tel" value={phone} onChange={handlePhoneChange} />
                                <FormErrorMessage>{phoneError}</FormErrorMessage>
                            </FormControl>
                            <FormControl display="flex" alignItems="center" isInvalid={!!agreeTermsError}  w={300} >
                                <Checkbox
                                    id="agreeTerms"
                                    size="lg"
                                    colorScheme="blue"
                                    isChecked={agreeTerms}
                                    onChange={handleAgreeTermsChange}
                                >
                                    <Text fontSize={'sm'}>{t("INFO_AGREE")}</Text>
                                </Checkbox>
                                <FormErrorMessage></FormErrorMessage>
                            </FormControl>
                            <FormControl display="flex" alignItems="center">
                                <Checkbox
                                    id="newsletter"
                                    size="lg"
                                    colorScheme="blue"
                                    isChecked={newsletter}
                                    onChange={handleNewsletterChange}
                                >
                                    <Text fontSize={'sm'}>{t("INFO_NEWSLETTER")}</Text>
                                </Checkbox>

                            </FormControl>
                            <Box>{i18n.language === "ro" ? t(theater.customMessage): t(theater.customMessageEng)} </Box>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_CAPTCHA_KEY ?? ''}
                                size="invisible"
                            />
                            {layout !== "mobile" && 
                                <Flex justifyContent={'center'} gap={6}>
                                    <CustomButton date={t("INFO_NEXT_UP")} hour={t("INFO_NEXT_DOWN")} soldOut={disableSubmit} height={60} onClick={handleSubmit}/>
                                </Flex>
                            }
                        </Stack>
                    </Flex>
                </Box>
                {layout === "mobile" &&
                    <Flex
                        position="fixed"
                        bottom={0}
                        width="100%"
                        justifyContent="space-between"
                        bg="white"
                        boxShadow="md"
                        zIndex="sticky"
                        >
                        <Box flexBasis="50%" bgColor={'#f58500'} p={1}>
                            <Grid templateColumns="100%" color="white">
                                <Text textAlign={"center"}> 
                                    {rep.seats.filter(seat => seat.isSelected).reduce((a, s) => a + getPriceName(s.selectedCategory).price, 0)} LEI                             
                                </Text>
                                <Flex alignItems={'center'} justifyContent={"center"}> <FaTicketAlt size={'25'}/> <Text ml={2}>{rep.seats.filter(seat => seat.isSelected).length}</Text>  </Flex>
                            </Grid>
                        </Box>
                        <Box flexBasis="50%">
                            <CustomButton date={t("SEATS_NEXT_UP")} hour={t("SEATS_NEXT_DOWN")} soldOut={disableSubmit} onClick={handleSubmit} height={60}/>
                        </Box>
                    </Flex>
                }
            </React.Fragment>
            )}
        </Flex>
    );
}

export default FillData;