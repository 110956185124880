import {Theater} from "../../core/types";
import {useContext} from "react";
import {ApiContext} from "../../contexts/apiContext";

const useTheatersRepo = () => {
    const { error, loading, get} = useContext(ApiContext);

    const getTheater = async () : Promise<Theater> => {
        const response = await get(`shop/theater`);
        return response as Theater;
    };

    return { error, loading, getTheater };
};

export default useTheatersRepo;

