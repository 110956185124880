import axios from "axios";
import {toast} from "react-toastify";

export const createAxiosInstance = () => {
    const axiosInstance =  axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        responseType: "json",
        headers: {
            "Content-Type": "application/json",
        },
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers["T"] = process.env.REACT_APP_THEATER_ID;
            config.headers["S"] = sessionStorage["sessionId"];
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            if (error.response.status === 400) {
                toast.error(error.response.data.join("\n"));
            }
            return Promise.reject(error);
        }
    );
    return axiosInstance;
}
