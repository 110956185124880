import {
    CompleteShowDetails,
    CreateOrder,
    CreateOrderResponse,
    FinishOrderRequest,
    ReserveRequest,
    SessionDto
} from "../../core/types";
import {useContext} from "react";
import {ApiContext} from "../../contexts/apiContext";

const useOrdersRepo = () => {
    const {get, post} = useContext(ApiContext);

    const reserveSeats = async (dto: ReserveRequest) : Promise<SessionDto> => {
        const response = await post('shop/seats/reserve', dto);
        return response as SessionDto;
    };
    const cancelSeats = async (): Promise<SessionDto> => {
        const response = await get('shop/seats/cancel');
        return response as SessionDto;
    };


    const createOrder = async (dto: CreateOrder) : Promise<CreateOrderResponse> => {
        const response = await post('shop/orders/create', dto);
        return response as CreateOrderResponse;
    };
    const cancelOrder = async (): Promise<SessionDto> => {
        const response = await get('shop/orders/cancel');
        return response as SessionDto;
    };
    const finishOrder = async (dto: FinishOrderRequest) : Promise<CompleteShowDetails> => {
        const response = await post('shop/orders/finish', dto);
        return response as CompleteShowDetails;
    };

    const retryOrder = async (id: string) : Promise<CreateOrderResponse> => {
        const response = await get(`shop/orders/retry/${id}`);
        return response as CreateOrderResponse;
    };

    return {createOrder, reserveSeats, cancelSeats, cancelOrder, finishOrder, retryOrder}
};

export default useOrdersRepo;
