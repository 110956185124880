import { Button, Text, Flex, Box } from '@chakra-ui/react';
import { FaArrowDown, FaArrowRight } from "react-icons/fa";

interface CustomButtonProps {
    date: string;
    hour: string;
    soldOut: boolean;
    onClick: () => void;
    height: number,
    arrowDown?: boolean
  }

  const CustomButton = (props: CustomButtonProps) => (
      <Button
          bg={props.soldOut ? "gray.300": "#fcad26"}
          overflow="hidden"
          color="#fff"
          px="20px"
          py="10px"
          h={props.height+ 'px'}
          borderRadius={0}
          position="relative"
          minWidth="225px"
          _hover={props.soldOut? {} : {
              bg: '#f29e0d',
              _after: {
                  width: '40%',
              },
          }}
          _active={props.soldOut? {} :{
              bg: '#f58500',
              top: '2px',
          }}
          _before={props.soldOut? {} :{
              content: '""',
              position: 'absolute',
              height: '100%',
              left: 0,
              top: 0,
              lineHeight: '3',
              fontSize: '125%',
              width: '40px',
          }}
          _after={props.soldOut? {} :{
              content: '""',
              position: 'absolute',
              width: '30%',
              height: '200%',
              background: 'rgba(255, 255, 255, 0.1)',
              zIndex: 1,
              right: 0,
              top: 0,
              margin: '-5px 0 0 -5px',
              transformOrigin: '0 0',
              transform: 'rotate(-20deg)',
              transition: 'width 0.5s ease',
          }}
          onClick={() => {if (!props.soldOut) props.onClick();}}>
        <Flex align='start' w='100%' alignItems={'center'} justifyContent={'space-between'}>
            <Box w={'120px'}>
                <Text fontSize={props.height > 40 ? 'lg': 'sm'}>{props.date}</Text>
                <Text fontSize={props.height > 40 ? 'sm': 'xs'}>{props.hour}</Text>
            </Box>
                <Box  w={'20px'}>
                {props.arrowDown && <FaArrowDown/>}
                {!props.arrowDown && <FaArrowRight/>} 
                </Box>
        </Flex>
      </Button>
  );

export default CustomButton;
