import { BtRepresentationFull } from "../core/types";
import { Box, Circle, Flex, Text } from "@chakra-ui/react";
import React from "react";
import {Fragment} from "react";
import { date2date, date2time } from "../core/helpers";
import { useTranslation } from "react-i18next";
import { FaCalendar, FaClock, FaMapMarker } from 'react-icons/fa';

interface RepDetailsProps {
    rep: BtRepresentationFull;
    currentPoint: number;
    isMobile?: boolean;
    isError?: boolean;
}

interface StepProps {
    isCurrent: boolean;
    isCompleted: boolean;
    index: number;
    label: string;
    zIndex: number;
    isError?: boolean;
  }

  const Step = (ps: StepProps) => {
    const color = ps.isError ? "red.500" : ps.isCurrent ? "gray.500" : ps.isCompleted ? "green.500" : "gray.300";
    
    return (
      <Flex direction="column" align="center" zIndex={ps.zIndex}>
        <Circle size="10" bg={color} color="white" fontWeight="bold">
          {ps.isCompleted ? "✓" : ps.isError && ps.isCurrent ? "x" : ps.index}
        </Circle>
        <Text fontSize="sm" color={color} mt={2}>{ps.label}</Text>
      </Flex>
    );
  };
  

const RepDetails: React.FC<RepDetailsProps> = ({ rep, currentPoint, isMobile,isError }) => {
  const { t, i18n } = useTranslation();
  const steps = [t("SEATS_STEP1"), t("SEATS_STEP2"),t("SEATS_STEP3"),t("SEATS_STEP4"),t("SEATS_STEP5")];

    return (
        <Fragment>
             <Flex justify="space-between" mx={isMobile ? 2 : 20} my={4} alignItems="center">
                {steps.map((s, index) => (
                    <React.Fragment key={index}>
                    <Step
                        index={index + 1}
                        zIndex={2}
                        isCurrent={index === currentPoint}
                        isCompleted={index < currentPoint}
                        label={s}
                        isError={index === currentPoint? isError: false}
                    />
                    {index < steps.length - 1 && (
                        <Box zIndex={1} mb={5} flex="1" ml={-5} mr={-5} height="2px" bg={index < currentPoint ? "green.500" : "gray.300" }  />
                    )}
                    </React.Fragment>
                ))}
            </Flex>

          
            <Flex alignItems="center" justifyContent="center" fontFamily={'Raleway'} fontSize={'2.2rem'}>
                <Text textAlign={'center'}>{i18n.language === "ro" ? rep.showName?.toUpperCase(): rep.showNameEng?.toUpperCase()}</Text>
            </Flex>
            <Flex alignItems="center" justifyContent="center" fontFamily={'Roboto Condensed'} fontSize={'xl'}>
              <FaMapMarker/><Text ml={2} mr={4}>{i18n.language === "ro" ? rep.roomName: rep.roomNameEng}</Text>
              <FaCalendar/><Text ml={2} mr={4}> {date2date(rep.date)}</Text>
              <FaClock/><Text ml={2}> {date2time(rep.date)}</Text>
            </Flex>
        </Fragment>
    );
};

export default RepDetails;
