import {SeatCategories} from "./enums";

export function date2date(dateStr: Date): string {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

export function date2time(dateStr: Date): string {
    const date = new Date(dateStr);
    const options = { hour: '2-digit', minute: '2-digit', hour12: false } as const;
    return date.toLocaleTimeString('en-GB', options);
}

export function buildImageUrl(url: string, size: string): string {
    return url + "/" + size;
}

export const getCategoryColor = (category: SeatCategories): string => {
    switch (category) {
        case SeatCategories.Category1:
            return '#5b70b6'; 
        case SeatCategories.Category2:
            return '#b2c3f2'; 
        case SeatCategories.Category3:
            return '#b283dd'; 
        case SeatCategories.Category4:
            return '#d8c1f0'; 
        case SeatCategories.Category5:
            return '#79d478'; 
        case SeatCategories.Category6:
            return '#c8eec6'; 
        case SeatCategories.Category7:
            return '#99b0ec'; 
        case SeatCategories.Category8:
            return '#b2c3f2'; 
        case SeatCategories.Category9:
            return '#cbd7f7';
        default:
            return '#000000'; // Black (fallback color)
    }
};