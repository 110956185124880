import React, { CSSProperties } from 'react';

interface VerticalTextProps {
  gridSize: number;
  text: string;
}

const VerticalText: React.FC<VerticalTextProps> = ({ gridSize, text }) => {

  const totalHeight = text.length * gridSize / 2; 
  const startOffset = -totalHeight / 2;

  return (
    <>
      {text.split("").map((char, index) => {
        const dynamicStyle: CSSProperties = {
          position: 'absolute',
          userSelect: 'none',
          top: `calc(50% + ${startOffset + (gridSize / 2) * index}px)`, 
          left: '50%',
          fontSize: `${gridSize/2}px`,
          lineHeight: `${gridSize/2}px`,
          transform: 'translate(-50%, -50%)',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        };

        return (
          <span style={dynamicStyle} key={index}>
            {char}
          </span>
        );
      })}
    </>
  );
};

export default VerticalText;
