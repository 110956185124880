import { Theater} from "../core/types";
import {Box, Center, HStack, Image, Img } from "@chakra-ui/react";
import {Fragment} from "react";
import { buildImageUrl } from "../core/helpers";
import { useTranslation } from "react-i18next";

interface TheaterHeaderProps {
    theater: Theater;
}

const TheaterHeader: React.FC<TheaterHeaderProps> = ({ theater }) => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
      };

    return (
        <Fragment>
        <Box position="relative"  mb={4} height={'100px'}>
            <Center height={'100px'}  overflow={"hidden"}>
                <Image  src={buildImageUrl(theater.onlineAvatar, 'm')} alt="Logo"/>
            </Center>
            
            <Box position="absolute" top={2} right={2}>
                <HStack>
                    <Img onClick={() => changeLanguage("ro")} src='/ro.svg' width={i18n.language === 'ro' ? '40px': '30px'} cursor={"pointer"}/>
                    <Img onClick={() => changeLanguage("en")} src='/gb.svg' width={i18n.language === 'en' ? '40px': '30px'} cursor={"pointer"}/>
                </HStack>
            </Box>
        </Box>
        </Fragment>
    );
};

export default TheaterHeader;
