import './App.css';
import ChooseShow from './pages/1ChooseShow/ChooseShow';
import {BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom';
import ChooseSeats from "./pages/2ChooseSeats/ChooseSeats";
import FillData from "./pages/3FillData/FillData";
import Completed from "./pages/4Completed/Completed";
import {ChakraProvider} from "@chakra-ui/react";
import {ApiContextProvider} from "./contexts/apiContext";
import { createAxiosInstance } from './contexts/axiosInstance';
import {Fragment, useMemo, useState} from "react";
import {MainContextProvider} from "./contexts/MainContext";
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Global, css } from '@emotion/react'
import { useTranslation } from "react-i18next";
import StopPage from './pages/StopPage';

const GlobalStyle = () => (
    <Global styles={css`
      /* Works on Chrome/Edge/Safari */
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
  
      ::-webkit-scrollbar-track {
        background: transparent; 
      }
  
      ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 2px;
      }
  
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
  
      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: #888 transparent;
    `} />
  )

function RedirectToHome() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, [navigate]);

    return null;
}

function App() {
    const axiosInstance = useMemo(() => createAxiosInstance(), []);
    const [ssEnabled, setSSEnabled] = useState<boolean>(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (typeof (Storage) !== "undefined" && window.sessionStorage) {
            setSSEnabled(true);
        } else {
            setSSEnabled(false);
        }
    }, []);

    useEffect(() => {
      document.title = `Bilete Online - ${process.env.REACT_APP_TITLE}`;
    }, []);

    return (
         !ssEnabled ? (
                <Fragment>{t("BAD_BROWSER")}</Fragment>
                ): (
                <ChakraProvider>
                    <ToastContainer />
                    <GlobalStyle />
                    <ApiContextProvider axiosInstance={axiosInstance}>
                        <MainContextProvider>
                            <Router>
                                <Routes>
                                    <Route path="/:showId?" element={<ChooseShow />} />
                                    <Route path="/seats/:repId" element={<ChooseSeats />} />
                                    <Route path="/fill-data" element={<FillData />} />
                                    <Route path="/completed" element={<Completed />} />
                                    <Route path="/stop" element={<StopPage />} />
                                    <Route path="*" element={<RedirectToHome />} />
                                </Routes>
                            </Router>
                        </MainContextProvider>
                    </ApiContextProvider>
                </ChakraProvider>
            )
    );
}

export default App;
