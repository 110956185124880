export enum SeatDesignations
{
    All = 10,
    Online = 100,
    Office = 200,
    Invitation = 300,
    Blocked = 350,
    Reserved = 400
}

export enum SeatTypes
{
    Unknown = 0,
    Empty = 100,
    Allocated = 200,
    Sold = 300,
    Taken = 400
}

export enum SeatCategories {
    Category1 = 1,
    Category2 = 2,
    Category3 = 4,
    Category4 = 8,
    Category5 = 16,
    Category6 = 32,
    Category7 = 64,
    Category8 = 128,
    Category9 = 256,
}

export enum SettingTypes
{
    IsOnlineSellingEnabled = 1,
    CancelSeatsAfterMinutes = 2,
    StopOnlineSellingBeforeMinutes = 3,
    MaxSeatsPerOrder = 4,
    MarkRepresentationsAsPlayedAfterMinutes = 5,
    WarnBeforeEndingSeries = 6,
}

export enum SessionStatuses
{
    Created = 10,
    Payed = 20,
    Failed = 30,
    Expired = 40,
    Error = 100
}