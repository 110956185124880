import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";


function ErrorPage() {
    const { t } = useTranslation();

    return (
        <Flex maxWidth="1240px" mx="auto" p={4}>
            <Box>{t("SERVER_ERROR")}</Box>
        </Flex>
    );
}

export default ErrorPage;