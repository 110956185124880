
import { Box, Text, Flex, Spinner, useBreakpointValue, Img} from "@chakra-ui/react";
import { useContext, useEffect, useState} from "react";
import { useLocation, useNavigate} from "react-router-dom";
import {BtRepresentationFull, CompleteShowDetails, CreateOrderResponse, FinishOrderRequest} from "../../core/types";
import useOrdersRepo from "../../hooks/api/useOrdersRepo";
import TheaterHeader from "../TheaterHeader";
import React from "react";
import { MainContext } from "../../contexts/MainContext";
import RepDetails from "../RepDetails";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


function Completed() {
    const {theater} = useContext(MainContext);
    const location = useLocation();
    const { finishOrder, retryOrder } = useOrdersRepo();
    const [details, setDetails] = useState<CompleteShowDetails>({} as CompleteShowDetails);
    const [uid, setUid] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [rep, setRep] = useState<BtRepresentationFull>({} as BtRepresentationFull);
    const { t } = useTranslation();
    const [retryDisabled, setRetryDisabled] = useState(false);
    const navigate  = useNavigate();
    const [disableOtherShow, setDisableOtherShow] = useState<boolean>(true);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        const finOrder = async () => {
            const queryParams = new URLSearchParams(location.search);
            setUid(queryParams.get('orderId'));
            const request: FinishOrderRequest = {
                orderId: queryParams.get('orderId'),
                token: queryParams.get('token'),
                approvalCode: queryParams.get('approvalCode'),
                refNum: queryParams.get('refNum'),
                language: queryParams.get('language'),
            };
            const response = await finishOrder(request);
            setDetails(response);
            setRep({
                date: response.date,
                id: '',
                roomAddress: response.roomAddress,
                roomAddressEng: response.roomAddress,
                roomLatitude: response.roomLatitude,
                roomLongitude: response.roomLongitude,
                roomName: response.roomName,
                showName: response.showName,
                roomNameEng: response.roomName,
                showNameEng: response.showName,
                bulkSeats: [],
                seats: [],
                objects: [],
                seatCategories: []
            } as BtRepresentationFull);
            setLoading(false);
            setRetryDisabled(false);
        }

        finOrder().catch(null);
    },[])
    /* eslint-disable react-hooks/exhaustive-deps */

    const retryTransaction = async () => {
        if (uid)
        {
            const response: CreateOrderResponse = await retryOrder(uid);

            if(response.isError) {
                toast.error(t("SESSION_EXPIRED"));
                setRetryDisabled(true);
            } else {
                window.location.href = response.paymentUrl;
            }
        }
    }

    const handleDownloadClick = () => {
        setDisableOtherShow(false);
    }


    const layout = useBreakpointValue({ base: "mobile", md: "desktop" });

    return (
        <Box maxWidth="1240px" mx="auto" p={4}>
          <TheaterHeader theater={theater}/>
          {loading ? (
              <Flex justifyContent="center" alignItems="center">
                  <Spinner size="xl" thickness="4px" color="blue.500" />
              </Flex>
          ) : (
            <React.Fragment>
                {details.isSuccess ? (
                    <Box boxShadow={'md'} backgroundColor={'white'} pt="5">
                        <RepDetails rep={rep} currentPoint={5} isMobile={layout === "mobile"}/>
                        <Flex justifyContent={"center"} mt={20}>
                            <Text fontFamily={"Roboto Condensed"} fontSize={'4xl'}>{t("END_OK")}</Text>
                        </Flex>
                        <Flex justifyContent={"center"} mt={10}>
                            <Img src='happy.png' w={300}/>
                        </Flex>
                        <Flex justifyContent={"center"} mt={10}>
                            <Text w={700} textAlign={'center'} fontFamily={"Roboto Condensed"} fontSize={'2xl'}>{t("END_OK_TEXT")}</Text>
                        </Flex>
                        <Flex justifyContent={"center"} mt={10} gap={6}>
                                <a href={`${process.env.REACT_APP_API_URL}${details.ticketsUrl}`} download onClick={handleDownloadClick}>
                                    <CustomButton date={t("END_DOWNLOAD")} hour={t("END_TICKETS")} soldOut={false} onClick={() => {}} height={60} arrowDown={true}/>
                                </a>
                                    <CustomButton date={t("SEATS_SHOW_UP")} hour={t("SEATS_SHOW_DOWN")} soldOut={disableOtherShow} onClick={()=> {navigate("/");}} height={60}/>
                        </Flex>
                        <Flex justifyContent={"center"} mt={10}>
                            <Box height={'20px'}></Box>
                        </Flex>
                    </Box>
                ) : (
                    <Box boxShadow={'lg'} backgroundColor={'white'}>
                    <Flex justifyContent={"center"} mt={20}>
                      <Text fontFamily={"Roboto Condensed"} fontSize={'4xl'}>{details.isError ? t("END_ERROR_TITLE"): t("END_FAILED")}</Text>
                    </Flex>
                    <Flex justifyContent={"center"} mt={10}>
                        <Img src='sad.png' w={300} />
                    </Flex>
                        {!details.isError && 
                        <Flex justifyContent={"center"} mt={10} gap={6}>
                            <CustomButton date={t("END_RETRY_UP")} hour={t("END_RETRY_DOWN")} soldOut={retryDisabled} onClick={retryTransaction} height={60} />
                        </Flex>
                        }
                        {details.isError &&
                               <Flex justifyContent={"center"} mt={10}>
                               <Text textAlign={'center'} w={700} fontFamily={"Roboto Condensed"} fontSize={'2xl'}>{t("END_ERROR")}</Text>
                           </Flex>}
                        <Flex justifyContent={"center"} mt={10}>
                      <Box height={'20px'}></Box>
                    </Flex>
                  </Box>
                )}
            </React.Fragment>
          )}
      </Box>
    );
}

export default Completed;