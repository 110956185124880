import ShowCard from "./ShowCard";
import {Box, Flex, Spinner, VStack} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import useShowsRepo from "../../hooks/api/useShowsRepo";
import {MainContext} from "../../contexts/MainContext";
import TheaterHeader from "../TheaterHeader";
import {BtShow} from "../../core/types";
import ErrorPage from "../ErrorPage";
import React from "react";


const MotionBox = motion(Box);

const fadeInAnimation = (index: number) => ({
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: index * 0.3, duration: 0.5 } },
});

function ChooseShow() {
    const {theater,unselectSeats, error} = useContext(MainContext);
    let { showId } = useParams();
    const [reps, setReps] = useState<BtShow[]>([]);
    const { getShowBySlug, getShows } = useShowsRepo();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchRep = async (showId: string) => {
        const rep = await getShowBySlug(showId);
        setReps([rep]);
        setLoading(false);
    }
    const fetchAllReps = async () => {
        const reps = await getShows();
        if (reps) setReps(reps);
        setLoading(false);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!showId) {
            fetchAllReps().catch(null);
        } else {
            fetchRep(showId).catch();
        }
        sessionStorage.removeItem("sessionId");
        unselectSeats();
    },[]);

    useEffect(() => {
        if (theater.isOnlineSellingEnabled === undefined) return;
        if (!theater.isOnlineSellingEnabled)
        {
            navigate("/stop");
            return;
        }
    },[theater]);
    /* eslint-enable react-hooks/exhaustive-deps */


    return (
        error ? (
            <ErrorPage />
        ):(
            <Box maxWidth="1240px" mx="auto" p={4}>
            {loading ? (
                <Flex justifyContent="center" alignItems="center">
                    <Spinner size="xl" thickness="4px" color="blue.500" />
                </Flex>
            ) : (
                <React.Fragment>
                    <TheaterHeader theater={theater}/>
                    <VStack gap={3}>
                    {reps.filter((s) => s.rooms.length > 0).map((show, index) => (
                        <MotionBox key={show.id} {...fadeInAnimation(index)} width={'100%'}>
                            <ShowCard show={show}/>
                        </MotionBox>
                    ))}
                    </VStack>
              </React.Fragment>
            )}
        </Box>
  
        )
    );
  }
  
  export default ChooseShow;