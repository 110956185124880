import { useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "@chakra-ui/react";
import TheaterHeader from "./TheaterHeader";
import { useNavigate } from "react-router-dom";




function StopPage() {
    const {theater} = useContext(MainContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        if (theater.isOnlineSellingEnabled === undefined) return;
        if (theater.isOnlineSellingEnabled)
        {
            navigate("/");
            return;
        }
    },[theater, navigate]);

    return (
        <Box maxWidth="1240px" mx="auto" p={4}>
            <TheaterHeader theater={theater}/>
            <Flex justifyContent={"center"}>
                <Box style={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
                    {t("MAINTENANCE_TITLE")}
                </Box>
            </Flex>
            <Flex justifyContent={"center"}>
                <Box style={{ fontSize: "18px", marginTop: "10px" }}>
                    {i18n.language === "ro" ? t(theater.noSellingMessage): t(theater.noSellingMessageEng)}
                </Box>
            </Flex>
        </Box>
    );
}

export default StopPage;